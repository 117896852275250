export const environment = {
  production: false,
  //PROD
  // baseUrl: 'https://eoyw6xnql5.execute-api.us-east-1.amazonaws.com/prod',
  // apiKey: 'lGb0Y4sWu1abi20okZRgf296xtOjgvVc7iE3ogq1',
  // ho
  // baseUrl : '/test',
  // baseUrl : 'https://ypqzanw4pk.execute-api.us-east-1.amazonaws.com/test',
  // apiKey : 'lGb0Y4sWu1abi20okZRgf296xtOjgvVc7iE3ogq',
  // //dev
  // baseUrl : 'https://93s7yo3re9.execute-api.us-east-1.amazonaws.com/dev',
  // baseUrl: '/dev',
  // apiKey : 'NOYxPMEyMU20fKwFJePPm1zA8Ib28MhN2C1RcQnp',
  // dev inatel
  // baseUrl : '/dev',
  baseUrl : 'https://tswq2hx9d2.execute-api.us-east-1.amazonaws.com/dev',
  apiKey : 'n65ubJIxtg1IDSDpAPu4w4JeFc3J3qnuaURekcu9',
// // 
  encryptKey: 'f05edd1711cefdf6fe19768c2039dfd7',

  //Urls para Planilhas de campanha
  URL_DEMO_WORKSHEET: 'https://psc-campanhas.s3.amazonaws.com/campanha/xls/modelo/sce.xls',
  URL_LAST_WORKSHEET: 'https://psc-campanha.s3.amazonaws.com/campanha/xls/sce.xls',

  // caracteres especiais (?=.*[!@#$%&*-+]) letras maiusculas (?=.*?[A-Z]) letras minusculas (?=.*?[a-z]) tamanho .{8,}
  PASSWORD_REQUIREMENTS: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$`,
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_REQUIRE_LOWERCASE: true,
  PASSWORD_REQUIRE_UPPERCASE: false,
  PASSWORD_REQUIRE_DIGITS: true,
  PASSWORD_REQUIRE_SYMBOLS: false,

  BAGS_PER_HECTARE: [0, 10, 20, 30, 40, 50, 60],
  AMOUNT_PER_MONTH: ['0-6', '7-18']
};