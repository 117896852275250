import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { IconSetService } from "@coreui/icons-angular";
import { freeSet } from "@coreui/icons";
import { UserLoginService } from "../app/core/services/user-login.service";
import { BasicModalComponent } from '../app/shared/basic-modal/basic-modal.component';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  // tslint:disable-next-line
  selector: "body",
  templateUrl: "app.html",
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  @ViewChild('myModal') public myModal: ModalDirective;
  modalRef: any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false
  };

  constructor(
    private router: Router,
    public iconSet: IconSetService,
    public userService: UserLoginService,
    public basicModal: BasicModalComponent,
  ) {
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() { }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    // 1- verifica se já existe token para o usuário, se não tiver direciona para o login
    // 2- verifica se o token é válido, se não for direciona para o login
    // 3- caso tudo ok, inicia função que fica rodando para verificar token
    // this.verifyUser();
    // if (localStorage.getItem('access_token') === null) {
    //   this.router.navigate(['/login']);
    // } else if (this.userService.checkExpiredToken()) {
    //   localStorage.clear();
    //   this.router.navigate(['/login']);
    //   return;
    // } else {
    //   this.verifyUser();
    // }

    // Atualizar token
    if (localStorage.getItem('access_token') !== null) {
      this.userService.verifyLastUpdateToken();
    }

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd && event.urlAfterRedirects) {
        if (event.urlAfterRedirects !== '/initial' && event.urlAfterRedirects !== '/login') {
          
        }
      }
    });
  }

  verifyUser() {
    let  id = setInterval(() =>{
      if (this.userService.checkExpiredToken() && localStorage.getItem('access_token') !== null) {
        // this.openModalSessionExpired();
        localStorage.clear();
        this.router.navigate(['/login']);
         clearInterval(id);
      } else if (localStorage.getItem('access_token') === null) {
        localStorage.clear();
        this.router.navigate(['/login']);
         clearInterval(id);
      }
    }, 3000);
  }

  modalConfig() {
    return {
      size: 'md',
      modalClass: 'modalGlobal',
      hideCloseButton: true,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: 'modal-backdrop',
    };
  }

  openModalSessionExpired() {
    localStorage.clear();
    this.myModal.config = this.config;
    this.myModal.show();
  }

  closeModal() {
    this.myModal.hide();
    this.userService.logout();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 1000);
  }
}
