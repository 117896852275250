import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { UserServiceService } from '../../shared/services/user-service.service';
@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(private http: HttpClient, private userService: UserServiceService) { 
   
  }

  getUrlUpload(file: File) {
    return new Promise((resolve, reject) => {
      const url = `${environment.baseUrl}/images/slideshow/init-upload`;
      const token = this.userService.decryptUsingAES256(
        localStorage.getItem("access_token")
      );
      this.http.post(url, {}, { headers: { 'x-api-key': environment.apiKey, Authorization: token }, params: { type: file.type, name: file.name } }).subscribe(
        (result) => {
          const headers = new HttpHeaders({ 'Content-Type': file.type });
          const req = new HttpRequest(
            'PUT',
            result['url'],
            file,
            {
              headers: headers,
            });
          this.http.request(req).subscribe(
            () => { },
            (error) => {
              console.log("Error.:", error);
              return reject(error);
            },
            () => {
              return resolve('Upload comcluído com sucesso!');
            }
          );
        }
      );
    });
  }

  getImages() {
    const url = `${environment.baseUrl}/images/slideshow`;
    const token = this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: { Authorization: token, 'x-api-key': environment.apiKey }}).subscribe(
        (results) => {
          return resolve(results);
        },
        error => {
          console.log('Error.:', error);
          return reject(error);
        }
      )
    })
  }

  createInDb(data) {
    const url = `${environment.baseUrl}/parameters/banners`;
    const token = this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );
    return new Promise((resolve, reject) => {
      this.http.post(url, data, { headers: { Authorization: token, 'x-api-key': environment.apiKey } }).subscribe(
        () => {},
        error => {
          console.log('Error.:', error);
          return reject(error);
        },
        () => {
          return resolve("Imagem adicionada com sucesso!");
        }
      )
    })
  }

  deleteImage(key) {
    const url = `${environment.baseUrl}/images/slideshow`;
    const token = this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );
    return new Promise((resolve, reject) => {
      this.http.delete(url, { headers: { Authorization: token, 'x-api-key': environment.apiKey }, params: { imageKey: key } }).subscribe(
        (res) => {           
          return resolve("Imagem excluída com sucesso!");
        },
        error => {
          console.log('Error.:', error);
          return reject(error);
        }
      )
    })
  }
}
