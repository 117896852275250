import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient} from '@angular/common/http';
import { UserServiceService } from '../services/user-service.service'

@Injectable({
  providedIn: 'root'
})
export class ModuleManagementService {

  constructor(
    private http: HttpClient,
    private userService: UserServiceService
    ) { }

  async getModules(isWeb: boolean){
    let url = `${environment.baseUrl}/parameters/module`;

    let token = await this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return new Promise((resolve, reject) => {
      return this.http
        .get(url, {
          headers: {
            "x-api-key": environment.apiKey,
            Authorization: token
          },
          params: { web: isWeb }
        })
        .subscribe(
          (result) => {
            return resolve(result);
          },
          (error) => {
            console.log(error);
            
            return reject(error);
          }
        );
    });
  }

  updateModules(modules) {
    let url = `${environment.baseUrl}/parameters/module`;

    let token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return this.http
      .put(url, modules, {
        headers: {
          "x-api-key": environment.apiKey,
          Authorization: token,
        },
      }
    );
  }
}
