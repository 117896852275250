import { UserLoginService } from './../../../services/user-login.service';
import { UserRegistrationService } from './../../../../shared/services/user-registration.service';
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NewPasswordUser } from '../../../../shared/models/user.model';


@Component({
  selector: 'app-passwordnew',
  templateUrl: './passwordnew.component.html',
  styleUrls: ['./passwordnew.component.scss']
})
export class PasswordnewComponent {
  errorMessage: string;
  loadPassword: boolean = false;
  fieldTextType: boolean;
  usuario: string
  mostraSpinner: boolean = false;


  constructor(
    public userRegistration: UserRegistrationService,
    public userService: UserLoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,) {

    this.router = router; { }
    }

    baseForm = this.formBuilder.group({
      userName: [ Validators.required],
      existingPassword: [null, Validators.required],
      password: [null, Validators.required],
  });


  ngOnInit(): void { this.errorMessage = null;
    this.userService.isAuthenticated(this);
    this.activatedRoute.params.subscribe(params => {
        this.baseForm.get('userName').setValue(params['userName'])
    });
  }
  onRegister() {


    // this.errorMessage = null;
    // try {
    //     if (this.baseForm.valid) {
    //         this.loadPassword = true;
    //         const formData = this.baseForm.value;

    //         const registrationUser = new NewPasswordUser()
    //             .setUsername(formData.userName)
    //             .setExistingPassword(formData.existingPassword)
    //             .setPassword(formData.password);

    //         this.userRegistration.newPassword(registrationUser);
    //         this.toastr.success("Solicitação enviada com sucesso")
    //     } else {
    //         this.loadPassword = false;
    //         this.verifyValidationForm(this.baseForm);
    //     }
    // } catch (error) {
    //     this.toastr.error("Ocorreu um erro na solicitação", "Redefinição de Senha")
    // }
}

cognitoCallback(message: string, _result: any) {
    try {
        if (message != null) {
            this.errorMessage = this.userService.validateMessage(message)
            this.loadPassword = false;
        } else {
            this.userService.logout();
            this.toastr.success("Senha redefinida com sucesso", "Redefinição de Senha");
            this.router.navigate(['/']);
        }
    } catch (error) {
        console.error(error)
    }

}

isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
        this.router.navigate(['/securehome/messages']);
    }
}

toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
}

verifyValidationForm(formGroup: UntypedFormGroup | UntypedFormArray) {
  Object.keys(formGroup.controls).forEach(campo => {
    const controle = formGroup.get(campo);
    controle.markAsDirty();
    controle.markAsTouched();
    if (controle instanceof UntypedFormGroup || controle instanceof UntypedFormArray) {
      this.verifyValidationForm(controle);
    }
  });
}
applyCssErro(campo: string) {
  return {
    'is-invalid': this.verifyInvalidTouchedDirty(campo),
    'is-valid': this.verifyValidTouchedDirty(campo)
  };
}
verifyInvalidTouchedDirty(field: string) {
  const form = this.baseForm.get(field);
  return this.verifyTouchedDirtyGeneric(form.invalid, form.touched, form.dirty);
}

verifyValidTouchedDirty(field: string) {
  const form = this.baseForm.get(field);
  return this.verifyTouchedDirtyGeneric(form.valid, form.touched, form.dirty);
}

verifyTouchedDirtyGeneric(verifier: boolean, touched: boolean, dirty: boolean) {
  return (verifier && (touched || dirty));
}

verifyErroRiquerid(field: string) {
  return this.baseForm.controls[field].errors.required;
}



}

