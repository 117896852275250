import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PushNotificationService } from '../../shared/services/push-notification.service';
import { ToastrService } from 'ngx-toastr';
import { UserServiceService } from '../../shared/services/user-service.service';
@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
  sendLoading: boolean = false;
  sendAll: boolean = false;
  notificationForm!: FormGroup;
  documentsInvalid = [];
  category = '';
  myRole = '';
  categorias = {
    'ADMINISTRADORES': [
      {
        value: 'PscGeneralTopic',
        name: 'Geral'
      },
      {
        value: 'CAMPAIGN',
        name: 'Campanha'
      },
      {
        value: 'CADASTRO',
        name: 'Cadastro'
      }
    ],
    'CADASTRO': [
      {
        value: 'CADASTRO',
        name: 'Cadastro'
      }
    ]
  };
  array = []

  constructor(private toastr: ToastrService, private pushService: PushNotificationService, private userService: UserServiceService) { 
    this.myRole = (this.userService.decryptUsingAES256(localStorage.getItem('roleEncripted'))).toUpperCase();
    this.array = this.categorias[this.myRole]
  }

  ngOnInit(): void {
    this.notificationForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      body: new FormControl('', [Validators.required]),
      category: new FormControl('PscGeneralTopic'),
      users: new FormControl(''),
      link: new FormControl('')
    })
  }
  
  get title() {
    return this.notificationForm.get('title')!;
  }

  get body() {
    return this.notificationForm.get('body')!;
  }

  filterNumbers() {    
    this.notificationForm.get('users').setValue(this.notificationForm.get('users').value.replace((/[^\d,]/g), '').replace(',,', ''));
  }
  
  submit() {
    this.sendLoading = true;
    this.sendAll = false;
    this.documentsInvalid = [];
    let arrayUsers = [];

    if(this.notificationForm.invalid) {
      this.sendLoading = false;
      return;
    }
    
    let usersString = this.notificationForm.value['users'];
    
    if(usersString === "") {
      this.notificationForm.get('users').setValue([]);
      this.sendAll = true;
    }

    if(usersString !== "") {
      if (usersString.startsWith(',')) {
        usersString = usersString.substring(1);
      }
  
      if (usersString.endsWith(',')) {
        usersString = usersString.substring(0, usersString.length - 1);
      }
  
      usersString = usersString.split(',');
  
      usersString.forEach((i: string) => {
        if(this.getFormattedCpfCnpj(i) !== ''){
          arrayUsers.push(this.getFormattedCpfCnpj(i));
        }
      });
      
      this.notificationForm.get('users').setValue(arrayUsers);
    }

    if(this.documentsInvalid.length > 0) {
      if(this.documentsInvalid.length === 1) {
        this.toastr.error(`O CPF/CNPJ está incorreto (${this.documentsInvalid.join(',')})`)
      } else {
        this.toastr.error(`Os CPFs/CNPJs estão incorretos (${this.documentsInvalid.join(',')})`)
      }
      this.sendLoading = false;
      return;
    }

    //extra validation to not send notification to all wrong 
    if (this.sendAll === false && arrayUsers.length <= 0) {
      return;
    }
    // console.log(this.notificationForm.value);
    
    this.pushService.sendNotification(this.notificationForm.value).then(res => {
      this.toastr.success("Notificação enviada!");
      this.notificationForm.reset({
        category: 'PscGeneralTopic',
        link: '',
        users: ''
      });
      this.sendLoading = false;
    }).catch(err => {
      this.toastr.error("Desculpe, ocorreu um erro ao enviar notificação.");
      this.sendLoading = false
    });
    
  }

  getFormattedCpfCnpj(documentOnlyDigits: string): string {
    let cpfOrCnpjFull = '';
    if (documentOnlyDigits.length === 11) {
      //CPF
      cpfOrCnpjFull = `${documentOnlyDigits.substr(
        0,
        3,
      )}.${documentOnlyDigits.substr(3, 3)}.${documentOnlyDigits.substr(
        6,
        3,
      )}-${documentOnlyDigits.substr(9, 2)}`;
    } else if (documentOnlyDigits.length === 14) {
      //CNPJ
      cpfOrCnpjFull = `${documentOnlyDigits.substr(
        0,
        2,
      )}.${documentOnlyDigits.substr(2, 3)}.${documentOnlyDigits.substr(
        5,
        3,
      )}/${documentOnlyDigits.substr(8, 4)}-${documentOnlyDigits.substr(
        12,
        2,
      )}`;
    } else {
      this.documentsInvalid.push(documentOnlyDigits);
      cpfOrCnpjFull = "";
    }
    return cpfOrCnpjFull;
  }

}