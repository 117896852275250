import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserServiceService } from './user-service.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private http: HttpClient,
    private userService: UserServiceService
  ) { }

  sendNotification(data) {
    const url = `${environment.baseUrl}/push-notifications/send-message`;
    const token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return new Promise((resolve, reject) => {
      this.http.post<any>(url, data, {headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
      }}).subscribe(res => {
        return resolve(res);
      },
      (err) => {
        return reject(err);
      })
    })
  }

  getNotifications(category: string) {
    const url = `${environment.baseUrl}/push-notifications`;
    const token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return new Promise((resolve, reject) => {
      this.http.get<any>(url, {headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
      }, params: {category: category}}).subscribe(res => {
        return resolve(res);
      },
      (err) => {
        return reject(err);
      })
    })
  }

  getReport(id: string, topic: string) {
    const url = `${environment.baseUrl}/push-notifications/report`;
    const token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return new Promise((resolve, reject) => {
      this.http.get<any>(url, {headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
      }, params: {pk: topic, sk: id}}).subscribe(res => {
        return resolve(res);
      },
      (err) => {
        return reject(err);
      })
    })
  }
}
