
import { Inject, Injectable } from "@angular/core";
import { RegistrationUser } from './../../core/auth/register/register.component';
import { NewPasswordUser } from "../models/user.model";

@Injectable()
export class UserRegistrationService {

    constructor() {

    }


    register(user) {
    
    }

    confirmRegistration(username: string, confirmationCode: string) {

    }

    newPassword(newPasswordUser) {
    
    }

}