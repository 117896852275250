import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserServiceService } from './user-service.service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private http: HttpClient,
    private userService: UserServiceService
  ) { }

  getAlerts(): Promise<any>{
    const url = `${environment.baseUrl}/parameters/alerts?admin=true`;

    const token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return new Promise((resolve, reject) => {
      this.http.get<any>(url, {headers: {
        "x-api-key": environment.apiKey,
        Authorization: token
      }}).subscribe(res => {
        return resolve(res);
      },
      (err) => {
        return reject(err);
      })
    })  
  }

  putAlerts(data): Promise<any> {
    const url = `${environment.baseUrl}//parameters/alerts`;

    const token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return new Promise((resolve, reject) => {
      this.http.put<any>(url, data, {headers: {
        "x-api-key": environment.apiKey,
        Authorization: token
      }}).subscribe(() => {
        this.getAlerts().then(res => {
          return resolve(res);
        }).catch(error => {
          return reject(error);
        })
      },
      (err) => {
        return reject(err);
      })
    })  

  }
}
