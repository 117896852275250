import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-erro-page',
  templateUrl: './erro-page.component.html',
  styleUrls: ['./erro-page.component.css']
})
export class ErroPageComponent implements OnInit {

  erroCode: number;
  erroMessage: string;
  erroIcon: any;
  erroTitle: string;
  erroHeader: string;
  erroText: string;
  erroTheme: string;
  erroTitleBreadcrumb: string;
  constructor(private route: ActivatedRoute,) { }

  ngOnInit() {
    this.erroCode = this.route.snapshot.params.code;
    this.messageErro(this.erroCode)
  }

  messageErro(code: number){

    if(code == 403){
      this.erroCode = code;
      this.erroMessage = 'Você não possue credenciais para acessar esse página. Para solicitar acesso entre em contado com o administrador do sistema.';
      //this.erroIcon = 'ionicons ion-android-remove-circle';
      this.erroIcon = 'fa fa-ban';
      this.erroTitle = 'Permissão Negada';

      this.erroText = 'text-red';
      this.erroTheme = 'box-danger';
      this.erroTitleBreadcrumb = 'Acesso Negado';
    }

    if(code == 404){
      this.erroCode = code;
      this.erroMessage = 'A página que você está procurando pode ter sido removida ou renomeada, ou o link que vc seguiu está incorreto.';
      this.erroIcon = 'fa fa-warning';
      this.erroTitle = 'Página não Encontrada';
      this.erroHeader = 'Error';
      this.erroText = 'text-yellow';
      this.erroTheme = 'box-warning';
      this.erroTitleBreadcrumb = 'Página não Encontrada';
    }

  }

}
