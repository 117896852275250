import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertsRoutingModule } from './alerts-routing.module';
import { AlertsComponent } from './alerts.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser'
import { DataTablesModule } from 'angular-datatables';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl  } from 'ng-pick-datetime';

export class DefaultIntl extends OwlDateTimeIntl  {

  /** A label for the cancel button */
  cancelBtnLabel= 'Cancelar';

  /** A label for the set button */
  setBtnLabel= 'Ok';


}
@NgModule({
  declarations: [
    AlertsComponent
  ],
  imports: [
    CommonModule,
    AlertsRoutingModule,
    NgxShimmerLoadingModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    DataTablesModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_LOCALE, useValue: 'br',
    },
    {provide: OwlDateTimeIntl, useClass: DefaultIntl}
  ]
})
export class AlertsModule { }
