import { HealthCheckService } from './shared/services/health-check.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { S3Service } from './core/services/s3.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { APP_BASE_HREF } from '@angular/common';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './core';


import { LoginComponent } from './core/auth/login/login.component';
import { RegisterComponent } from './core/auth/register/register.component';
// import { DynamoDBService } from './core/services/ddb.service';
import { UserLoginService } from './core/services/user-login.service';
// import { AwsUtil } from "./core/services/aws.service";

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { BasicModalComponent } from './shared/basic-modal/basic-modal.component';
import { LogoutComponent } from './core/auth/logout/logout.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { UserRegistrationService } from './shared/services/user-registration.service';
import { PasswordnewComponent } from './core/auth/login/passwordnew/passwordnew.component';
import { ModuleManagementService } from './shared/services/module-management.service';
import { BannersService } from './shared/services/banners.service';
import { AlertsService } from './shared/services/alerts.service';
import { AlertsModule } from './modules/alerts/alerts.module';
import { PushNotificationComponent } from './modules/push-notification/push-notification.component';
import { HealthComponent } from './health/health.component';
import { HealthModule } from './health/health.module';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { NgxMaskModule } from 'ngx-mask';
// import { ModuleManagementComponent } from './modules/module-management/module-management.component';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule,
    ToastrModule.forRoot(),
    ModalModule,
    FormsModule,
    CommonModule,
    AlertsModule,
    HealthModule,
    NgxShimmerLoadingModule,
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false
    })
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    RegisterComponent,
    BasicModalComponent,
    LogoutComponent,
    PasswordnewComponent,
    PushNotificationComponent,
    HealthComponent
    // ModuleManagementComponent,
  ],

  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    // {
    //   provide: LocationStrategy,
    //   // useClass: PathLocationStrategy,
    //   useClass: HashLocationStrategy,
    // },
    // {
    //   provide: LocationStrategy,
    //   useClass: PathLocationStrategy
    // useClass: HashLocationStrategy
    // },

    IconSetService,
    UserLoginService,
    BsModalService,
    BasicModalComponent,
    DatePipe,
    UserRegistrationService,
    ModuleManagementService,
    BannersService,
    AlertsService,
    HealthCheckService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
