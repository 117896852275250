import { Component, OnInit } from '@angular/core';
import { HealthCheckService } from '../shared/services/health-check.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {

  constructor(
    private healthCheckService: HealthCheckService
  ) { }

  mainAlert = {};
  listMonitoring = [];
  listModules = [];

  selectedMonitoring = {};

  loadingMainAlert: boolean = false;
  loadingServices: boolean = false;
  loadingMonitoring: boolean = false;

  isOnline: boolean = true;

  ngOnInit() {
    this.getMonitoring();
    this.getModules();
    this.getMainAlert();
  }

  // Alerta principal
  getMainAlert() {
    this.loadingMainAlert = true;
    this.healthCheckService.getAlerts().then(res => {

      if (res[0] == undefined) {
        this.healthCheckService.getMonitoring(true).then(result => {
          if(result['Items'][0]) {

          
          let aux = result['Items'][0];
          aux['tipo'] = aux['evento'].toLowerCase();
          aux['mensagemPt'] = aux['mensagem'];

          this.getDataMainAlert(aux);
        } else {
          this.loadingMainAlert = false;
        }
        });
      } else {
        this.getDataMainAlert(res[0]);
      }
    });
  }

  // Trata os dados do alert principal
  getDataMainAlert(res) {
    this.loadingMainAlert = false;
    this.mainAlert = res;

    switch (this.mainAlert['tipo']) {
      case 'warning':
        this.mainAlert['tipo'] = 'Aviso';
        this.mainAlert['color_icon'] = '#856900';
        break;

      case 'maintenance':
        this.mainAlert['tipo'] = 'Manutenção';
        this.mainAlert['color_icon'] = '#856900';
        this.isOnline = false;
        break;

      case 'info':
        this.mainAlert['tipo'] = 'Informativo';
        this.mainAlert['color_icon'] = '#005FA0';
        break;

      case 'danger':
        this.mainAlert['tipo'] = 'Erro';
        this.mainAlert['color_icon'] = '#F45454';
        break;

      case 'error':
        this.mainAlert['tipo'] = 'Erro';
        this.mainAlert['color_icon'] = '#F45454';
        break;

      case 'success':
        this.mainAlert['tipo'] = 'Sucesso';
        this.mainAlert['color_icon'] = '#00543C';
        break;
    }

    let auxStart = this.mainAlert['intervaloAtivo']['de'];
    let auxEnd = this.mainAlert['intervaloAtivo']['ate'];

    this.mainAlert['intervaloAtivo']['de'] = auxStart.substring(8, 10) + '/' + auxStart.substring(5, 7) + '/' + auxStart.substring(0, 4) + ' - ' + auxStart.substring(11, 19);
    this.mainAlert['intervaloAtivo']['ate'] = auxEnd.substring(8, 10) + '/' + auxEnd.substring(5, 7) + '/' + auxEnd.substring(0, 4) + ' - ' + auxEnd.substring(11, 19);
  }

  // Lista de Modulos
  getModules() {
    let status: string = '';
    let name: string = '';
    let icon: string = '';
    this.loadingServices = true;
    this.healthCheckService.getModules().then(res => {
      this.loadingServices = false;
      
      Object.keys(res).forEach(mod => {
        if (res[mod]['controle'] != 'isHidden') {

          // Status do módulo
          switch (res[mod]['controle']) {
            case 'isActive':
              status = 'Ativo';
              break;

            case 'isInactive':
              status = 'Inativo';
              break;
          }

          // Nome e icone do módulo
          switch (mod) {
            case 'campaign':
              name = 'Campanhas';
              icon = 'fi fi-sr-megaphone';
              break;
            case 'commercialize':
              name = 'Comercialização';
              icon = 'fi fi-sr-tags';
              break;
            case 'commitments':
              name = 'Compromissos';
              icon = 'fi fi-sr-lock';
              break;
            case 'credit_limits':
              name = 'Limite de Crédito';
              icon = 'fi fi-sr-settings-sliders';
              break;
            case 'financial_position':
              name = 'Posição Financeira';
              icon = 'fi fi-sr-file-invoice-dollar';
              break;
            case 'laboratory':
              name = 'Laboratório';
              icon = 'fi fi-sr-test-tube';
              break;
            case 'links':
              name = 'Links';
              icon = 'fi fi-sr-link';
              break;
            case 'others':
              name = 'Outros';
              icon = 'fi fi-sr-plus';
              break;
            case 'quotes':
              name = 'Cotação';
              icon = 'fi fi-sr-dollar';
              break;
            case 'registration_update':
              name = 'Atualização Cadastral';
              icon = 'fi fi-sr-user';
              break;
            case 'reports':
              name = 'Relatórios';
              icon = 'fi fi-sr-document';
              break;
            case 'storage':
              name = 'Estoque';
              icon = 'fi fi-sr-database';
              break;
            case 'weather':
              name = 'Clima';
              icon = 'fi fi-sr-clouds';
              break;
            case 'billet':
              name = 'Boleto';
              icon = 'fi fi-sr-barcode';
              break;
            case 'events':
              name = 'Evento';
              icon = 'fi fi-sr-calendar-star';
              break;
            case 'pending_actions':
              name = 'Pendências';
              icon = 'fi fi-sr-arrow-alt-circle-up';
              break;
            case 'salesAgreement':
              name = 'Acordo de venda';
              icon = 'fi fi-sr-box-alt';
              break;
            default:
              name = mod;
              icon = 'fi fi-sr-image-slash';
          }

          this.listModules.push({
            'module': name,
            'status': status,
            'icon': icon
          });
        }
      });
    });
  }

  // Lista de Monitoramento
  getMonitoring() {
    this.loadingMonitoring = true;
    this.healthCheckService.getMonitoring(false).then(res => {
      this.loadingMonitoring = false;
      this.listMonitoring = res;

      this.listMonitoring.sort((a, b) => (a['inicio'] < b['inicio']) ? 1 : ((b['inicio'] < a['inicio']) ? -1 : 0));

      this.listMonitoring.forEach(item => {
        let aux: String = item['tipo'].toLowerCase();
        item['tipo'] = aux.charAt(0).toLocaleUpperCase() + aux.slice(1);

        aux = item['pk'].toLowerCase();
        item['pk'] = aux.charAt(0).toLocaleUpperCase() + aux.slice(1);

        aux = item['evento'].toLowerCase();
        switch (aux) {
          case 'warning':
            item['evento'] = 'Alerta';
            break;

          case 'error':
            item['evento'] = 'Erro';
            break;

          case 'info':
            item['evento'] = 'Info';
            break;
        }
      });
    });
  }

  // Seleciona a mensagem de monitoramento
  selectMonitoring(item) {
    this.selectedMonitoring = item;
  }
}
