import { environment } from "../../../environments/environment";
export class UserModel {
    username: string;
    name: string;
    email: string;
    phone_number: string;
    password: string;
    group: any[];

    setUsername(username: string) {
        this.username = username;
        return this;
    }

    setName(name: string) {
        this.name = name;
        return this;
    }

    setEmail(email: string) {
        this.email = email;
        return this;
    }

    setPhoneNumber(phone_number: string) {
        this.phone_number = phone_number;
        return this;
    }

    setPassword(password: string) {
        this.password = password;
        return this;
    }

    setGroup(group: string[]) {
        this.group = group;
        return this;
    }
}

export class AwsUserModel {
    UserPoolId: string;
    Username: string;
    ForceAliasCreation: boolean;
    TemporaryPassword: string;
    DesiredDeliveryMediums: any[];
    UserAttributes: any[];

    constructor(){
    }

    setUsername(Username: string) {
        this.Username = Username;
        return this;
    }

    setForceAliasCreation(ForceAliasCreation: boolean) {
        this.ForceAliasCreation = ForceAliasCreation;
        return this;
    }

    setTemporaryPassword(TemporaryPassword: string) {
        this.TemporaryPassword = TemporaryPassword;
        return this;
    }

    setDesiredDeliveryMediums(DesiredDeliveryMediums: any[]) {
        this.DesiredDeliveryMediums = DesiredDeliveryMediums;
        return this;
    }

    setUserAttributes(UserAttributes: any[]) {
        this.UserAttributes = UserAttributes;
        return this;
    }
}

export class AwsUserGroupModel {
    GroupName: string;
    UserPoolId: string
    Username: string;

    constructor(){
    }

    setGroupName(GroupName: string) {
        this.GroupName = GroupName;
        return this;
    }

    setUsername(Username: string) {
        this.Username = Username;
        return this;
    }
}

export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;

  setUsername(username: string) {
      this.username = username;
      return this;
  }

  setExistingPassword(existingPassword: string) {
      this.existingPassword = existingPassword;
      return this;
  }

  setPassword(password: string) {
      this.password = password
      return this;
  }
}

export class userLogged {
    role: string;
    name: string;
    user: string;
    email:string;
    matricula: string;
}