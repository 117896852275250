import { Injectable } from "@angular/core";
import { QueryModel } from "./../../shared/models/query.model";
import { environment } from "../../../environments/environment";
import { EmployeeModel } from "../models/employee.model";
import { UserServiceService } from "./user-service.service";
import { HttpClient} from '@angular/common/http';
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  private currentEmployee: any;

  constructor(private userService: UserServiceService, private http: HttpClient) {}

  getCurrentEmployee(): any {
    return this.currentEmployee;
  }

  setCurrentEmployee(data: any) {
    this.currentEmployee = data;
  }

  userCreate(data: EmployeeModel) {
    let token = this.userService.decryptUsingAES256(localStorage.getItem("access_token"));

    return this.http.post<any>(`${environment.baseUrl}/user/register`, JSON.stringify(data), {
      headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
  }

  userUpdate(data: EmployeeModel) {
    let token = this.userService.decryptUsingAES256(localStorage.getItem("access_token"));

    return this.http.put<any>(`${environment.baseUrl}/user/register`, JSON.stringify(data), {
      headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
  }

  passwordUpdate(data: any) {
    let token = this.userService.decryptUsingAES256(localStorage.getItem("access_token"));

    return this.http.post<any>(`${environment.baseUrl}/user/change-password`, JSON.stringify(data), {
      headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
  }

  async findByUsername(username: string) {
    // const dbClient = new DynamoDB.DocumentClient();

    // const queryFilter = new QueryModel()
    //   .setTableName(TABLE_FUNCIONARIOS)
    //   .setIndexName("enabled-nome-colaborador-index")
    //   .setKeyConditionExpression("ENABLED = :isEnabled")
    //   .setFilterExpression("USUARIO = :username")
    //   .setExpressionAttributeValues({
    //     ":isEnabled": 1,
    //     ":username": username,
    //   });

    // let dbResult = await dbClient.query(queryFilter).promise();
    // return dbResult.Items[0];
  }

  listAll() {
    let url = `${environment.baseUrl}/users/detailed`;
    let token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );

    return this.http.get(url, {
      headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
      }
    });
  }

  async findById(codigo: string) {
    // const dbClient = new DynamoDB.DocumentClient();
    // let params = {
    //   TableName: TABLE_FUNCIONARIOS,
    //   KeyConditionExpression: "CD_COLABORADOR = :codigo",
    //   ExpressionAttributeValues: {
    //     ":codigo": codigo,
    //   },
    // };

    // const dbResult = await dbClient.query(params).promise();
    // return dbResult.Items[0];
  }


  async getUsuariosRecenseadorAndAgronomo() {
    // const dbClient = new DynamoDB.DocumentClient();

    // const queryFilter = new QueryModel()
    //     .setTableName(TABLE_FUNCIONARIOS)
    //     .setIndexName('enabled-nome-colaborador-index')
    //     .setKeyConditionExpression('ENABLED = :isEnabled')
    //     .setFilterExpression("TP_FUNCAO IN (:perfilA, :perfilB)")
    //     .setExpressionAttributeValues({
    //         ":isEnabled": 1,
    //         ":perfilA": "RECENSEADOR",
    //         ":perfilB": "AGRONOMO",
    //     });

    // try {
    //     let dbResult = await dbClient.query(queryFilter).promise();
    //     return dbResult.Items
    // } catch (err) {
    //     return [];
    // }
}

async filter(codigo: string, nome: string, usuario: string, perfis: any[], status: string) {
  // const dbClient = new DynamoDB.DocumentClient();

  // const queryFilter = this.setQueryFilter(codigo, nome, usuario, perfis, status);

  // try {
  //     const dbResult = await dbClient.scan(queryFilter).promise();
  //     return dbResult.Items
  // } catch (err) {
  //     return [];
  // }
}
private setQueryFilter(codigo: string, nome: string, usuario: string, perfis: any[], status: string) {

  // const AND = " AND ";
  // let filterExpression: string = "";
  // let expressionAttribuiteValues: any = {};

  // let params = new QueryModel()
  //     .setTableName(TABLE_FUNCIONARIOS)
  //     .setIndexName('enabled-nome-colaborador-index')
  //     .setKeyConditionExpression('ENABLED = :isEnabled')

  // filterExpression += 'ENABLED = :isEnabled';
  // filterExpression += AND;
  // expressionAttribuiteValues[":isEnabled"] = 1;

  // if (codigo) {
  //     filterExpression += "CD_COLABORADOR = :codigo";
  //     filterExpression += AND;
  //     expressionAttribuiteValues[":codigo"] = codigo;
  // }

  // if (nome) {
  //     filterExpression += "contains(NM_COLABORADOR, :nome)";
  //     filterExpression += AND;
  //     expressionAttribuiteValues[":nome"] = nome;
  // }

  // if (usuario) {
  //     filterExpression += "contains(USUARIO, :usuario)";
  //     filterExpression += AND;
  //     expressionAttribuiteValues[":usuario"] = usuario;
  // }

  // if (perfis.length > 0) {
  //     const attribuites = {};
  //     perfis.forEach((value) => {
  //         const name = `:perfil${perfis.indexOf(value)}`;
  //         attribuites[name] = value;
  //     });

  //     for (const attr in attribuites) {
  //         expressionAttribuiteValues[attr] = attribuites[attr];
  //     }
  //     const paramPerfil = Object.keys(attribuites)
  //     filterExpression += `TP_FUNCAO IN (${paramPerfil})`;
  //     filterExpression += AND;
  // }

  // if (status != null) {
  //     filterExpression += "ACCOUNT_STATUS = :status";
  //     filterExpression += AND;
  //     expressionAttribuiteValues[":status"] = status;
  // }

  // if (filterExpression !== "") {
  //     params.setFilterExpression(this.replaceLastWord(filterExpression));
  // }
  // params.setExpressionAttributeValues(expressionAttribuiteValues);

  // return params;
}
replaceLastWord(value: string) {
  const index = value.lastIndexOf('AND');
  const result = value.slice(0, index) + value.slice(index).replace('AND', '');
  return result.trim();
}
}
