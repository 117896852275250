import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from '../../services/user-login.service';

@Component({
  selector: 'app-logout',
  template: ""
})

export class LogoutComponent {

  constructor(
    public router: Router,
    public userService: UserLoginService
    ) {}

    ngOnInit(){
      this.isLoggedIn()
    }
  

  isLoggedIn() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
