import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserServiceService } from '../../shared/services/user-service.service';
import { UserLoginService } from '../services/user-login.service';
@Injectable({
  providedIn: "root",
})
export class AuthGuardServiceGuard implements CanActivate {
  constructor(
    public router: Router,
    private userService: UserServiceService,
    private userLogin: UserLoginService) { }

canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // TODO MOCK
    // const groups = ["ADMINISTRADOR"];
    return new Promise(async res => {
      const role = await this.userService.decryptUsingAES256(localStorage.getItem('roleEncripted'));
      const expectedRole = route.data['expectedRole'];

      if (localStorage.getItem('access_token') === null) {
        localStorage.clear();
        this.router.navigate(['/login']);
        res(false);
      }
      else if (this.userLogin.checkExpiredToken()) {
        localStorage.clear();
        this.router.navigate(['/login']);
      }  

        // this.openModalSessionExpired();
      
      if(expectedRole !== undefined) {
        let index = expectedRole.indexOf(role.toUpperCase());
        
        if(index > -1){
          res(true);
        } else {
          this.router.navigate(['/erro-page/403'])
          res(false);
        }

      } else {
        res(true)
      }
    })
   
    // const { groups, username } = this.userService.decodeToken();
    // const permissionPage = expectedRole;
    // const intersectPermission = groups.filter((permission: string) => {
    //     return permissionPage.indexOf(permission) > -1;
    // });

    // if (intersectPermission.length == 0) {
    //     this.router.navigate(['/erro-page/403']);
    //     return false;
    // }
    
}
}

// export class AuthGuardServiceGuarde implements CanActivate {
//   constructor(private router: Router, private userService: UserLoginService) {}

//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ):
//     | Observable<boolean | UrlTree>
//     | Promise<boolean | UrlTree>
//     | boolean
//     | UrlTree {
//     // this.router.navigate(['/login']);
//     this.userService.isAuthenticated(this);
//     return false;
//   }

//   isLoggedIn(message: string, isLoggedIn: boolean) {
//     if (!isLoggedIn) {
//       this.router.navigate(["/login"]);
//     }

//   }
// }
