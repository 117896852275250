import { Component, OnInit, Pipe } from '@angular/core';
import { ChartType, ChartOptions, Chart } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { DashboardService } from '../../shared/services/dashboard.service';
import { orders, dashboard, condicoes } from './dashboard.model';
import { UserServiceService } from '../../shared/services/user-service.service';
import { environment} from '../../../environments/environment';
@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  Object = Object;

  rolesCampaigns = ['ADMINISTRADORES', 'COMERCIAL']

  dadosDashboard: dashboard;
  loadDashboard: boolean = false;
  isAuthorized: boolean = false;
  campaignActive: boolean = true;
  valuesChart = [];
  namesChart = [];

  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    language: {
      lengthMenu: "Mostrar _MENU_ resultados por página",
      search: "Filtrar por:",
      zeroRecords: "Nada encontrado",
      info: "Exibindo _START_ a _END_  de _TOTAL_ resultados",
      infoFiltered: "(filtrado de total de _MAX_ resultados)",
      infoEmpty: "0 Resultados",
      loadingRecords: "Carregando...",
      processing: "Processando...",
      paginate: {
        first: "<<",
        last: ">>",
        next: ">",
        previous: "<",
      },
    },
  }; 
  pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend:{ position: "left"}
    
   };
  pieChartLabels: Label[] = this.namesChart;
  pieChartData: SingleDataSet = this.valuesChart;
  pieChartType: ChartType = 'pie';
  pieChartLegend = false;
  pieChartPlugins = [];

  
  chartColors = [{backgroundColor:['#00543C', '#FFCA00', '#EF7D00', '#007237', '#47260E']}];
  colors = ['#00543C', '#FFCA00', '#EF7D00', '#007237', '#47260E'];

  constructor(private dashboardService: DashboardService, private userService: UserServiceService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.getData(); 
  }
  public formatter(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }
  openPageProds() {
    window.open('http://appj.cooxupe.com.br:8089/campanha/itens.jsp', '_blank');
  }

  async getData() {
    this.campaignActive = true;
    const role = await this.userService.decryptUsingAES256(localStorage.getItem('roleEncripted'));

    if(this.rolesCampaigns.indexOf(role.toUpperCase()) > -1) {
      this.isAuthorized = true;
      this.loadDashboard = false;
      let campaignActive = await this.dashboardService.getDataCampaignActive();
      if(!campaignActive) {
        this.campaignActive = false;
      }
      this.dashboardService.dashboard({initialDate: campaignActive}).then((res: dashboard) => {
        
        Object.keys(res.cond_pagamento).forEach(key => {
          this.namesChart.push(`${key} (${(res.cond_pagamento[key].total)})`);
          this.valuesChart.push(res.cond_pagamento[key].total);
        });
        res.orders.forEach(item => {
          item.cpf = item.pk.split('_')[1];
          item.mat = item.pk.split('_')[2];
        });
        this.dadosDashboard = res;
        
        this.loadDashboard = true;
      })
    } else {
      this.isAuthorized = false;
    }
  }
}