// import { CognitoCallback } from './../../services/cognito.service';
import { UserRegistrationService } from './../../../shared/services/user-registration.service';
import {Component} from "@angular/core";
import {Router} from "@angular/router";



export class RegistrationUser {
    name: string;
    email: string;
    phone_number: string;
    password: string;
}
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
  registrationUser: RegistrationUser;
  router: Router;
  errorMessage: string;

  constructor(public userRegistration: UserRegistrationService, router: Router) {
      this.router = router;
      this.onInit();
  }

  onInit() {
      this.registrationUser = new RegistrationUser();
      this.errorMessage = null;
  }

  onRegister() {
  
  }

  cognitoCallback(message: string, result: any) {
      if (message != null) { //error
          this.errorMessage = message;
      } else { //success
          //move to the next step
          this.router.navigate(['/home/confirmRegistration', result.user.username]);
      }
  }
}
