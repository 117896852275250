import {Component} from '@angular/core';
import { navAgro, navCadastro, navComer, navComu, navExpositor, navItems, navPas } from '../../_nav';
import { UserLoginService } from '../services/user-login.service';
import { EmployeeService } from '../../shared/services/employee.service'
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  loggedUserPermission: string[];
  loggedUser: string;
  role;
  constructor(public userService: UserLoginService, public employeeService: EmployeeService) {
    this.role = JSON.parse(localStorage.getItem('role'));
    // this.loggedUserPermission = this.userService.jwtPayload["cognito:groups"];
    this.permissions();
  }

  public navItems = [];
  public sidebarMinimized = false;
  mostra = true;

  // load user permissions
  permissions() {
    if (this.role === 'Administradores') {
      this.navItems = navItems;
    }
    if (this.role === 'Cadastro') {
      this.navItems = navCadastro;
    }
    if(this.role === 'Comercial') {
      this.navItems = navComer
    }
    if(this.role === 'Expositores') {
      this.navItems = navExpositor
    }
    if (this.role === 'PAS') {
      this.navItems = navPas
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

//   async getUserProfile() {
//     const { username, groups } = this.userService.decodeToken();
//     const userProfile = await this.employeeService.findByUsername(username);
//     if (userProfile.NM_COLABORADOR) {
//         this.loggedUser = userProfile.NM_COLABORADOR.split(/(\s).+\s/).join("");
//     }
//     const userData = {
//         codigo: userProfile.CD_COLABORADOR,
//         nomePerfil: userProfile.NM_COLABORADOR,
//         permission: groups
//     }
//     localStorage.setItem('userData', this.encryptData(userData, ENCRYPT_SECRET_KEY));
// }
// encryptData(data: any, encryptSecretKey: string): string {
//   try {
//       return AES.encrypt(JSON.stringify(data), encryptSecretKey).toString();
//   } catch (e) {
//       console.error(e);
//   }
// }
}
