import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserServiceService as UserService } from './user-service.service';
import { environment } from "../../../environments/environment";
import * as moment from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor( private userService: UserService, private http: HttpClient) { }

  dashboard(data:Object) {    
    const url = `${environment.baseUrl}/campaign/dashboard?de=${data['initialDate']}`;
    const token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );
      
    return new Promise((resolve, reject) => {
      this.http.get<any>(url, {headers: {
        "x-api-key": environment.apiKey,
        Authorization: token,
      }}).subscribe(res => {
        return resolve(res);
      },
      (err) => {
        return reject(err);
      })
    });
  }

  getDataCampaignActive() {
    const url = `${environment.baseUrl}/campaign/initial`;
    const token =  this.userService.decryptUsingAES256(
      localStorage.getItem("access_token")
    );
      
    return new Promise((resolve, reject) => {
      return this.http.get<any>(url, {headers: {
        "x-api-key": environment.apiKey,
        Authorization: token}}).subscribe(result => {
          
          if(result.dt_inicio) {
            let data = new Date(result.dt_inicio).setUTCMinutes(180);
            
            return resolve(moment(data).format("DD/MM/YYYY"))
          } else {
            return resolve(undefined)
          }
        })
    })
  }
}


