import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { userLogged } from './../../shared/models/user.model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class UserLoginService {
  public userSubject = new BehaviorSubject<userLogged>(undefined);
  public user: Observable<userLogged>;
  constructor(private router: Router, private http: HttpClient) {
  }

  checkExpiredToken() {
    if (localStorage.getItem('access_token') !== null) {
      const currentDate = new Date();
      let curreteDateToken = JSON.parse(localStorage.getItem('exp'));
      curreteDateToken = new Date(curreteDateToken).getTime() + (60000 * 59)

      if (currentDate > curreteDateToken) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  authenticate(username: string, password: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.baseUrl}/user/sign-in`,
        { username, password },
        {
          headers: {
            'x-api-key': environment.apiKey,
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(
        map(async (user) => {
          let userObj = new userLogged();
          
          userObj = {
            role: user.signInUserSession.idToken.payload['cognito:groups'][0],
            email: user.attributes.email,
            name: user.signInUserSession.idToken.payload['given_name'],
            matricula: user.signInUserSession.idToken.payload['custom:matriculas'],
            user: user.signInUserSession.accessToken.payload.username,
          };

          localStorage.setItem('user', JSON.stringify(userObj));
          localStorage.setItem('name', JSON.stringify(this.encryptUsingAES256(user.attributes.given_name)));
          localStorage.setItem('username', JSON.stringify(
            this.encryptUsingAES256(
              user.signInUserSession.accessToken.payload.username
            )
          )
          );
          localStorage.setItem('email', JSON.stringify(this.encryptUsingAES256(user.attributes.email))
          );
          localStorage.setItem('access_token', JSON.stringify(
            this.encryptUsingAES256(user.signInUserSession.idToken.jwtToken)
          )
          );

          localStorage.setItem('refresh_token', JSON.stringify(
            this.encryptUsingAES256(user.signInUserSession?.refreshToken?.token)
          )
          );

          localStorage.setItem('matriculas', JSON.stringify(
            this.encryptUsingAES256(user['attributes']['custom:matriculas'])
          )
          );
          localStorage.setItem('exp', JSON.stringify(new Date()));
          localStorage.setItem('roleEncripted', JSON.stringify(
            this.encryptUsingAES256(
              user.signInUserSession.idToken.payload['cognito:groups'][0]
            )
          )
          );
          localStorage.setItem('role', JSON.stringify(
            user.signInUserSession.idToken.payload['cognito:groups'][0]
          )
          );

          this.userSubject.next(userObj);
            
            return user;
        })
      );
  }

  encryptUsingAES256(item) {
    let _key = CryptoJS.enc.Utf8.parse(environment['encryptKey']);
    let _iv = CryptoJS.enc.Utf8.parse(environment['encryptKey']);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
  }

  decryptUsingAES256(item) {

    let _key = CryptoJS.enc.Utf8.parse(environment['encryptKey']);
    let _iv = CryptoJS.enc.Utf8.parse(environment['encryptKey']);

    if (CryptoJS && item) {
      let decrypted = CryptoJS.AES.decrypt(
        JSON.parse(item), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });

      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } else {
      return ''
    }
  }

  forgotPassword(username: string) {
  }

  confirmNewPassword(
    email: string,
    verificationCode: string,
    password: string
  ) {
  }

  logout() {
    localStorage.clear();
  }

  isAuthenticated(callback) {
  }

  validateMessage(message: any) {
    let messagePTBR = 'Ocorreu um erro na solicitação';

    if (typeof message == 'string') {
      if (message.includes('User is disabled')) {
        messagePTBR = 'Usuário inativo, entre em contato com o administrador.';
        return messagePTBR;
      }

      if (message.includes('Incorrect username')) {
        messagePTBR = 'Usuário ou senha incorretos.';
        return messagePTBR;
      }

      if (message.includes('Password attempts exceeded')) {
        messagePTBR = 'Número de tentativas de senha excedidas.';
        return;
      }

      if (message.includes('Attempt limit exceeded')) {
        messagePTBR = 'Limite de tentativas excedido, tente após 20 minutos!';
        return messagePTBR;
      }

      if (message.includes('Invalid verification code')) {
        messagePTBR = 'Código de verificação inválido.';
        return messagePTBR;
      }

      if (message.includes('User needs to set password.')) {
        messagePTBR = 'PASSWORD_RESET';
        return messagePTBR;
      }

      if (message.includes('User does not exist.')) {
        messagePTBR = 'Usuário ou senha incorretos.';
        return messagePTBR;
      }

      if (message.includes('Username/client id combination not found.')) {
        messagePTBR = 'O usuário informado não existe.';
        return messagePTBR;
      }

      if (
        message.includes(
          'Cannot reset password for the user as there is no registered/verified email or phone_number'
        )
      ) {
        messagePTBR =
          'Não é permitido redefinir senha de usuários com email não verificado.';
        return messagePTBR;
      }

      if (
        message.includes(
          "validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6"
        )
      ) {
        messagePTBR =
          'Senha inválida, informe a senha obedecendo o padrão de segurança.';
        return messagePTBR;
      }

      if (message.includes('Password does not conform to policy')) {
        messagePTBR =
          'Senha inválida, informe a senha obedecendo o padrão de segurança.';
        return messagePTBR;
      }

      if (
        message.includes('User password cannot be reset in the current state')
      ) {
        messagePTBR =
          'No momento o status dessa conta não permite a redefinição de senha.';
        return messagePTBR;
      }
    }

    if (typeof message == 'object') {
      if (message.code == 'InvalidPasswordException') {
        messagePTBR =
          'Senha inválida, informe a senha obedecendo o padrão de segurança.';
        return messagePTBR;
      }

      if (message.code == 'NotAuthorizedException') {
        messagePTBR = 'Usuário ou senha incorretos.';
        return messagePTBR;
      }

      if (message.code == 'UserNotFoundException') {
        messagePTBR = 'O usuário informado não existe.';
        return messagePTBR;
      }
    }

    return messagePTBR;
  }

  verifyLastUpdateToken() {
    const currentDate = new Date().getTime();
    //exp é a data e horario que o usuário logou
    if (localStorage.getItem('exp') !== null) {
      let curreteDateRefrashToken = JSON.parse(localStorage.getItem('exp'));
      curreteDateRefrashToken = new Date(curreteDateRefrashToken).getTime() + 60000 * 1;

      if (currentDate > curreteDateRefrashToken) {
        this.updateSession();
        return;
      } else {
        return;
      }
    }
  }

  updateSession() {
    let api = `${environment.baseUrl}/user/refresh-token`;
    this.http
      .post(api,
        {
          refreshToken: this.decryptUsingAES256(localStorage.getItem('refresh_token')),
        },
        {
          headers: {
            'x-api-key': environment.apiKey,
            Authorization: this.decryptUsingAES256(localStorage.getItem('access_token')),
            'Content-Type': 'application/json',
          },
        }).subscribe(
          (data) => {
            localStorage.setItem('access_token', JSON.stringify(this.encryptUsingAES256(data['AuthenticationResult']['IdToken'])));
            localStorage.setItem('exp', JSON.stringify(new Date()));
            return;
          },
          (error) => {
            localStorage.clear();
            this.router.navigate(['/login']);
            return;
          }
        );
  }
}
