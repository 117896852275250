import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { FormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserLoginService } from "../../services/user-login.service";
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
class Client {
  userName: string;
  password: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  formClient = this.formBuilder.group({
    userName: [null, Validators.required],
    password: [
    null,
    [
      Validators.required,
      Validators.pattern(environment.PASSWORD_REQUIREMENTS),
    ],
  ]
  })
  errorMessage: string;
  mostraSpinner: boolean = false;
  loadLogin: boolean = false;
  loadRedefinirSenha: boolean = false;
  mfaStep = false;
  mfaData = {
      destination: '',
      callback: null
  };

  constructor(private formBuilder: UntypedFormBuilder, public userService: UserLoginService, public router: Router, private toastr: ToastrService,) {}

  ngOnInit(){
    // this.createForm(new Client());
    // localStorage.clear();
  }

  createForm(client: Client){
    
  }

  onSubmit() {
    if (!this.formClient.valid) {
      this.errorMessage = "Todos os campos são obrigatorios";
    } else {
      this.mostraSpinner = true;
      try {
        this.userService.authenticate(this.formClient.value.userName, this.formClient.value.password).pipe(first()).subscribe(
          {
            next: async (res) => {
              let result = await res;
              this.mostraSpinner = false;
              this.router.navigate(['/dashboard']);
            },
            error: (message) => {              
              if (message != null) {
                this.errorMessage = this.userService.validateMessage(message.error);
                this.mostraSpinner = false;
              }
            }
          }
        );        
      } catch (error) {
        this.errorMessage = "Desculpe ocorreu um erro interno."
        this.mostraSpinner = false;
      }
    }
  }
  applyCssError(field) {
    return {
      "is-invalid": this.verifyValidTouched(field),
    };
  }
  verifyValidTouched(field) {
    return !this.formClient.get(field).valid && this.formClient.get(field).touched;
  }
}
