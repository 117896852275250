import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fi fi-sr-dashboard',

  },
  {
    title: true,
    name: 'Perfil Administrador'
  },
  {
    name: 'Meu perfil',
    url: '/profile',
    icon: 'fi fi-sr-id-badge'
  },
  {
    name: 'Usuários',
    url: '/users',
    icon: 'fi fi-sr-user'
  },
  // {
  //   name: 'Gerenciamento de Campos',
  //   url: '/field-management',
  //   icon: 'fi fi-sr-align-justify'
  // },
  // {
  //   name: 'Gerenciamento de Modulos',
  //   url: '/module-management',
  //   icon: 'fi fi-sr-apps-add'
  // },
  {
    name: 'Gerenciamento de Modulos',
    url: '/module-management',
    icon: 'fi fi-sr-apps',
    children: [
      {
        name: 'Modulos APP',
        url: '/module-management/app',
        icon: 'fi fi-sr-mobile-button'
      },
      {
        name: 'Modulos WEB',
        url: '/module-management/web',
        icon: 'fi fi-sr-computer'
      }
    ]
  },
  {
    name: 'Banners',
    url: '/banners',
    icon: 'fi fi-sr-picture'
  },
  {
    name: 'Links Úteis',
    url: '/links',
    icon: 'fi fi-sr-link'
  },
  {
    name: 'Alertas PSC',
    url: '/alerts',
    icon: 'fi fi-sr-exclamation'
  },
  {
    name: 'Agendamento de CRON',
    url: '/schedule-cron',
    icon: 'fi fi-sr-calendar'
  },
  {
    name: 'Enquetes',
    url: '/survey',
    icon: 'fi fi-sr-chart-pie'
  },
  {
    name: 'Notificações',
    url: '/push',
    icon: 'fi fi-sr-envelope',
    children: [
      {
        name: 'Enviar',
        url: '/push/send',
        icon: 'fi fi-sr-paper-plane'
      },
      {
        name: 'Relatórios',
        url: '/push/reports',
        icon: 'fi fi-sr-rectangle-list'
      }
    ]
  },
  {
    name: 'Campanha',
    url: '/campaign',
    icon: 'fi fi-sr-megaphone',
    children: [
      {
        name: 'Produtos',
        url: '/campaign/products',
        icon: 'fi fi-sr-grid'
      },
      {
        name: 'Campanhas',
        url: 'campaign/campaigns',
        icon: 'fi fi-sr-menu-burger'
      },
      {
        name: 'Fornecedores',
        url: 'campaign/providers',
        icon: 'fi fi-sr-truck-loading'
      },
      {
        name: 'Comercial',
        url: '/campaign/commercial',
        icon: 'fi fi-sr-file-invoice-dollar'
      },
      {
        name: 'Vendedores',
        url: '/campaign/sellers',
        icon: 'fi fi-sr-users-alt'
      },
      {
        name: 'Campanha Ativa',
        url: '/campaign/active-campaign',
        icon: 'fi fi-sr-megaphone'
      }
    ]
  },
  {
    name: 'Custo de Produção',
    url: '/production-cost',
    icon: 'fi fi-sr-calculator-money',
    children: [
      {
        name: 'Todos os custos',
        url: '/production-cost/all',
        icon: 'fi fi-sr-sack-dollar'
      },
      {
        name: 'Produtos/Serviços',
        url: '/production-cost/products-services',
        icon: 'fi fi-sr-square-poll-horizontal'
      }
    ]
  }
];
export const navCadastro= [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fi fi-sr-dashboard',
  },
  {
    title: true,
    name: 'Perfil Cadastro'
  },
  {
    name: 'Meu perfil',
    url: '/profile',
    icon: 'fi fi-sr-id-badge'
  },
  {
    name: 'Usuários',
    url: '/users',
    icon: 'fi fi-sr-user'
  },
  {
    name: 'Notificações',
    url: '/push',
    icon: 'fi fi-sr-envelope',
    children: [
      {
        name: 'Enviar',
        url: '/push/send',
        icon: 'fi fi-sr-paper-plane'
      },
      {
        name: 'Relatórios',
        url: '/push/reports',
        icon: 'fi fi-sr-rectangle-list'
      }
    ]
  }
]

export const navAgro = [

  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fi fi-sr-dashboard',

  },
  {
    title: true,
    name: 'Admin'
  },
  {
    name: 'Meu perfil',
    url: '/profile',
    icon: 'fi fi-sr-id-badge'
  },

  {
    name: 'Aprovação Cadastral',
    url: '/registration-approval',
    icon: 'fi fi-sr-file-check',
    children: [
      {
        name: 'Listar',
        url: '/registration-approval/registration-list',
        icon: 'fi fi-sr-list'
      },
    ]
  },


]

export const navComer = [

  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fi fi-sr-dashboard',

  },
  {
    title: true,
    name: 'Comercial'
  },
  {
    name: 'Meu perfil',
    url: '/profile',
    icon: 'fi fi-sr-id-badge'
  },

  {
    name: 'Campanha',
    url: '/campaign',
    icon: 'fi fi-sr-megaphone',
    children: [
      {
        name: 'Produtos',
        url: '/campaign/products',
        icon: 'fi fi-sr-grid'
      },
      {
        name: 'Campanhas',
        url: 'campaign/campaigns',
        icon: 'fi fi-sr-menu-burger'
      },
      {
        name: 'Fornecedores',
        url: 'campaign/providers',
        icon: 'fi fi-sr-truck-loading'
      },
      {
        name: 'Comercial',
        url: '/campaign/commercial',
        icon: 'fi fi-sr-file-invoice-dollar'
      },
      {
        name: 'Vendedores',
        url: '/campaign/sellers',
        icon: 'fi fi-sr-users-alt'
      },
      {
        name: 'Campanha Ativa',
        url: '/campaign/active-campaign',
        icon: 'fi fi-sr-megaphone'
      }
    ]
  }


]

export const navExpositor = [

  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fi fi-sr-dashboard',

  },
  {
    title: true,
    name: 'Comercial'
  },
  {
    name: 'Meu perfil',
    url: '/profile',
    icon: 'fi fi-sr-id-badge'
  },

  {
    name: 'Campanha',
    url: '/campaign',
    icon: 'fi fi-sr-megaphone',
    children: [
      {
        name: 'Campanha Ativa',
        url: '/campaign/active-campaign',
        icon: 'fi fi-sr-megaphone'
      }
    ]
  },


]

export const navComu = [

  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fi fi-sr-dashboard',

  },
  {
    title: true,
    name: 'Admin'
  },
  {
    name: 'Meu perfil',
    url: '/profile',
    icon: 'fi fi-sr-id-badge'
  },

  {
    name: 'Notificações',
    url: '/notifications',
    icon: 'fi fi-sr-bell',
    children: [
  {
      name: 'Listar',
        url: '/notifications/list',
        icon: 'fi fi-sr-list'
      },
      {
        name: 'Novo',
        url: '/notifications/new',
        icon: 'fi fi-sr-plus'
      },
    ]
  },


]
export const navPas = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fi fi-sr-dashboard',

  },
  {
    name: 'Custo de Produção',
    url: '/production-cost',
    icon: 'fi fi-sr-calculator-money',
    children: [
      {
        name: 'Todos os custos',
        url: '/production-cost/all',
        icon: 'fi fi-sr-sack-dollar'
      },
      {
        name: 'Produtos/Serviços',
        url: '/production-cost/products-services',
        icon: 'fi fi-sr-square-poll-horizontal'
      }
    ]
  }
]


