import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PasswordnewComponent } from './core/auth/login/passwordnew/passwordnew.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './core';

import { LoginComponent } from './core/auth/login/login.component';
import { LogoutComponent } from './core/auth/logout/logout.component';
import { RegisterComponent } from './core/auth/register/register.component';
import { AuthGuardServiceGuard as authGuard } from './core/services/auth-guard-service.guard';
import { ErroPageComponent } from './shared/erro-page/erro-page.component';
import { HealthComponent } from './health/health.component';


const homeRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'health',
    component: HealthComponent,
    data: {
      title: 'Health Page'
    }
  },
  {
    path: 'passwordnew/:userName',
    component: PasswordnewComponent,
    data: {
      title: 'New Password Page'
    }
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },

  },]

const secureHomeRoutes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/dashboard',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [authGuard]
      },
      { path: 'logout', component: LogoutComponent },
      {
        path: 'erro-page/:code', component: ErroPageComponent,
        data: {
          title: 'Error'
        }
      },

      {
        path: 'profile',
        canActivate: [authGuard],
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
      },

      {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES', 'COMUNICACAO']
        },
      },

      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES', 'CADASTRO'],

        }
      },
      {
        path: 'field-management',
        loadChildren: () => import('./modules/field-management/field-management.module').then(m => m.FieldManagementModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES', 'CADASTRO'],

        }
      },
      {
        path: 'module-management',
        loadChildren: () => import('./modules/module-management/module-management.module').then(m => m.ModuleManagementModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES'],
        }
      },
      {
        path: 'banners',
        loadChildren: () => import('./modules/banners/banners.module').then(m => m.BannersModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES'],
        }
      },
      {
        path: 'links',
        loadChildren: () => import('./modules/links/links.module').then(m => m.LinksModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES'],
        }
      },
      {
        path: 'alerts',
        loadChildren: () => import('./modules/alerts/alerts-routing.module').then(m => m.AlertsRoutingModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES'],
        }
      },
      {
        path: 'schedule-cron',
        loadChildren: () => import('./modules/schedule-cron/schedule-cron.module').then(m => m.ScheduleCronModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES'],
        }
      },
      {
        path: 'survey',
        loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES'],
        }
      },
      {
        path: 'push',
        loadChildren: () => import('./modules/push-notification/push-notification.module').then(p => p.PushNotificationModule),
        canActivate: [authGuard],
        data: {
          expectedRole: ['ADMINISTRADORES', 'CADASTRO']
        }
      },
      {
        path: 'campaign',
        loadChildren: () => import('./modules/campaign/campaign.module').then(m => m.CampaignModule),
        canActivate: [authGuard]
      },
      {
        path: 'production-cost',
        loadChildren: () => import('./modules/production-cost/production-cost.module').then(m => m.ProductionCostModule),
        canActivate: [authGuard]
      }
    ]
  }
];

const routes: Routes = [
  {
    path: '',
    children: [
      ...homeRoutes,
      ...secureHomeRoutes,
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },


];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
