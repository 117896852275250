import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

  constructor(
    private http: HttpClient
  ) { }

  getMonitoring(isPainel): Promise<any> {
    let url = `${environment.baseUrl}/messages`;

    if (isPainel) {
      url += '?painel=true';
    }

    return new Promise((resolve, reject) => {
      this.http.get<any>(url, {
        headers: {
          "x-api-key": environment.apiKey,
        }
      }).subscribe(res => {
        return resolve(res);
      },
        (err) => {
          return reject(err);
        })
    })
  }

  getAlerts() {
    const url = `${environment.baseUrl}/parameters/alerts`;

    return new Promise((resolve, reject) => {
      this.http.get<any>(url, {
        headers: {
          "x-api-key": environment.apiKey,
        }
      }).subscribe(res => {
        return resolve(res);
      },
        (err) => {
          return reject(err);
        })
    })
  }

  getModules() {
    const url = `${environment.baseUrl}/parameters/module`;

    return new Promise((resolve, reject) => {
      this.http.get<any>(url, {
        headers: {
          "x-api-key": environment.apiKey,
        }
      }).subscribe(res => {
        return resolve(res);
      },
        (err) => {
          return reject(err);
        })
    })
  }
}
