import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-intention',
  templateUrl: './sales-intention.component.html',
  styleUrls: ['./sales-intention.component.scss']
})
export class SalesIntentionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
