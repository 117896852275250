import { Injectable } from "@angular/core";
import { AwsUserModel, UserModel } from "../models/user.model";
import * as AWS from "aws-sdk/global";
import { environment } from "../../../environments/environment";
import { AwsUserGroupModel } from "../models/user.model";
const AWS_ADMIN_USER = require("aws-sdk");
import { HttpClient} from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: "root",
})
export class UserServiceService {
  constructor(private http: HttpClient) {}

  private awsConfig() {
    return new AWS_ADMIN_USER.CognitoIdentityServiceProvider();
  }

  async register(user: UserModel) {
    let awsUserModel = new AwsUserModel()
      .setUsername(user.username)
      .setForceAliasCreation(true || false)
      .setTemporaryPassword(user.password)
      .setDesiredDeliveryMediums(["EMAIL"])
      .setUserAttributes([
        this.setAttributes("email", user.email),
        this.setAttributes("email_verified", "true"),
      ]);

    await this.awsConfig().adminCreateUser(awsUserModel).promise();
  }

  setAttributes(nameParam: string, valueParam: string) {
    let attribute: any = {};
    attribute = {
      Name: nameParam,
      Value: valueParam,
    };
    return attribute;
  }

  async addUserGroup(username: string, groups: any[]) {
    for (let groupName of groups) {
      let awsUserGroupModel = new AwsUserGroupModel()
        .setGroupName(groupName)
        .setUsername(username);

      await this.awsConfig().adminAddUserToGroup(awsUserGroupModel).promise();
    }
  }

  async removeUserGroup(username: string, groups: any[]) {
    for (let groupName of groups) {
      let awsUserGroupModel = new AwsUserGroupModel()
        .setGroupName(groupName)
        .setUsername(username);

      await this.awsConfig()
        .adminRemoveUserFromGroup(awsUserGroupModel)
        .promise();
    }
  }

  async activeUser(username: string, active: boolean) {
    let params = {
      Username: username
    };

    if (active) {
      return this.awsConfig().adminEnableUser(params).promise();
    } else {
      return this.awsConfig().adminDisableUser(params).promise();
    }
  }
// **************************************************************************************************** */
// **************************************************************************************************** */

  async listProfile(): Promise<any> {
    const url = `${environment.baseUrl}/groups`;

    const token = await this.decryptUsingAES256(
      localStorage.getItem('access_token')
    );

    return new Promise((resolve, reject) => {
      return this.http
        .get(url, {
          headers: {
            'x-api-key': environment.apiKey,
            Authorization: token,
          },
        })
        .subscribe(
          (result) => {
            return resolve(result);
          },
          (error) => {
            return reject(error);
          }
        );
    });
  }

  decryptUsingAES256(item: any) {
    const _key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const _iv = CryptoJS.enc.Utf8.parse(environment.encryptKey);

    if (CryptoJS && item !== null) {
      const decrypted = CryptoJS.AES.decrypt(
        JSON.parse(item), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });

      return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } else {
      return '';
    }
  }
}
